<template>
	<div>
		<div style="width: 100%; height: 100%;">
		<div class="b-container">
			<div class="b-wrapper-page">
				<div :class="{'b-filter': true}">
					<button class="b-filter__button">
						<h2>Подписки</h2>
					</button>
					<form class="b-filter__hidden" onsubmit="return false">
						<div class="b-filter__form1">
							<div class="b-input b-input--dual b-input--dual b-input--clinical  b-input--search input-group">
								<!-- Наименование КР -->
								<div class="b-input b-input--dual b-input--dual b-input--clinical b-input--search">
									<span class="email-text">E-mail</span>
									<input id="search-filter-namee" placeholder="Email" v-model="email" class="b-input__input-field1 b-input__input-field--dual b-input__input-field--dual b-input__input-field--clinical1 b-input__input-field--search" type="search" name="search-filter-name" />
								</div>
							</div>
							<button @click="changeEmail()" style="height: 48px; margin: 31px 0 0 12px; background: #fff; border:1px solid #0061d9; color: #0061d9;" class="b-button b-button--fill">
								<span>Изменить</span>
							</button>
						</div>
					</form>
				</div>
				<div class="tabs">
					<div class="tabs-header">
						<button class="tabs-btn" @click="updateTab(1)" :class="{ active: activeTab === 1 }">Код МКБ-10</button>
						<button class="tabs-btn" @click="updateTab(2)" :class="{ active: activeTab === 2 }">Разработчик</button>
					</div>
					<div class="tabs-body">
						<button @click="openDialog()" style="height: 48px; margin: 31px 0 0 12px; background: #0061d9; color: #fff;" class="b-button b-button--fill">
							<span>+Добавить</span>
						</button>
						<div v-for="(item, index) in pageSubscriptions.mkbs" :key="'mkb' + index" class="tabs-body-item" v-show="activeTab === 1">
							<div class="title-block1">
								<span style="margin-right: 10px;">{{ item.name }}</span>
								<iconCloseSvg @click="deleteItems(item.id, activeTab)" class="b-icon b-icon--close icon-close icon"/>
							</div>
						</div>
						<div v-for="(item, index) in pageSubscriptions.companies" :key="'company' + index" class="tabs-body-item" v-show="activeTab === 2">
							<div class="title-block1">
								<span style="margin-right: 10px;">{{ item.name }}</span>
								<iconCloseSvg @click="deleteItems(item.id, activeTab)" class="b-icon b-icon--close icon-close icon"/>
							</div>
						</div>
						<div class="b-wrapper-page__bottom top">
							<div class="b-pagination">
								<ul class="b-pagination__list">
									<li class="b-pagination__item-prev">
										<a @click="prevPage()" :class="{ 'b-button': true, 'b-button--slider': true, 'b-button--disabled': pageNumber == 0 }" href="javascript:void(0);" title="">
											<iconAngleleftSvg class="b-icon icon-angle-left"/>
										</a>
									</li>
									<li class="b-pagination__item">
										<span>{{ resultCount > 0 ? pageNumber + 1 : 0 }} из {{ resultCount }} стр</span>
									</li>
									<li class="b-pagination__item-next">
										<a @click="nextPage()" :class="{ 'b-button': true, 'b-button--slider': true, 'b-button--disabled': pageNumber >= resultCount - 1 }" href="javascript:void(0);" title="">
											<iconAnglerightSvg class="b-icon icon-angle-left"/>
										</a>
									</li>
								</ul>
							</div>
							<div class="b-number-page">
								<div class="b-number-page__text">
									Показать кол-во строк
									<span></span>
								</div>
								<div class="b-number-page__text">
									<select @change="updateListEvent(size)" v-model="size">
										<option :value="5">5</option>
										<option :value="10">10</option>
										<option :value="20">20</option>
										<option :value="30">30</option>
									</select>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div :class="{'background-block': dialog }" class="background">
			<div class="sub-window">
				<div class="title-block">
					<h2>{{ activeTab === 1 ? 'Код МКБ-10' : 'Разработчик' }}</h2>
					<iconCloseSvg @click="close" class="b-icon b-icon--close icon-close icon"/>
				</div>
				<div style="width: 400px; margin-top: 30px;" >
					<div v-if = "activeTab === 1" class="b-select b-select--dual b-select--clinical b-select--type">
						<v-select placeholder="Код МКБ-10" :filterable="false" @open="onOpenMkb" @close="onCloseMkb" @search="query => searchMkb = query" :closeOnSelect="false" :clearable="false" multiple v-model="searchResultMkb" :options="paginatedMkb" label="codeName">
							<template v-slot:no-options="{ search, searching }">
								<template v-if="searching"> Поиск не дал результатов <em>{{ search }}</em>.</template>
							</template>
							<template #list-footer>
								<li ref="load" class="loader" v-show="hasNextPage">
									Список прогружается...
								</li>
							</template>
						</v-select>
					</div>
					<div v-else class="b-select b-select--dual b-select--clinical b-select--type">
						<v-select placeholder="Разработчик" :reduce="obj => obj.id" :closeOnSelect="false" :clearable="false" multiple v-model="searchResultDev" :options="сompaniesList" label="name">
								<template v-slot:no-options="{ search, searching }">
										<template v-if="searching"> Поиск не дал результатов <em>{{ search }}</em>.</template>
								</template>
						</v-select>
					</div>
				</div>
				<div style="display: flex; margin-top: 10px;">
					<button @click="close" style="height: 48px; margin: 31px 0 0 12px; background: #fff; border:1px solid #0061d9; color: #0061d9;" class="b-button b-button--fill">
						<span>Отменить </span>
					</button>
					<button @click="addOptions()"  style="height: 48px; margin: 31px 0 0 12px; background: #0061d9; color: white;" class="b-button b-button--fill">
						<span>Добавить</span>
					</button>
				</div>

			</div>
		</div>
    		<snackbar position="top-center" ref="snackbar" baseSize="100px" :wrapClass="''" color="info" :holdTime="4000"/>
		</div>
	</div>
</template>

<script>
import iconSvg from "../../assets/icons/svg/filter.svg"
import iconCloseSvg from "../../assets/icons/svg/close.svg"
import iconSearchSvg from "../../assets/icons/svg/search.svg"
import iconAngleDownSvg from "../../assets/icons/svg/angle-down.svg"
import iconAngleleftSvg from "../../assets/icons/svg/angle-left.svg"
import iconAnglerightSvg from "../../assets/icons/svg/angle-right.svg"
import iconDownloadSvg from "../../assets/icons/svg/download.svg"
import iconSortdownSvg from "../../assets/icons/svg/sort-down.svg"
import iconSortupSvg from "../../assets/icons/svg/sort-up.svg"
import iconEditSvg from "../../assets/icons/svg/edit.svg"
import iconEyeSvg from "../../assets/icons/svg/eye.svg"

import { RingLoader } from '@saeris/vue-spinners'


import Dropdown from '../../components/Dropdown/index'
import Dashboards from '../../components/Dashboards/index'
import {mapGetters, mapActions} from 'vuex'

export default {
	name: 'listCr',
	components: { RingLoader, Dashboards, Dropdown, iconEditSvg, iconEyeSvg, iconSvg, iconSearchSvg, iconAngleDownSvg, iconCloseSvg, iconDownloadSvg, iconSortdownSvg, iconSortupSvg, iconAngleleftSvg, iconAnglerightSvg },
	computed: {
		...mapGetters({
			media: 'layout/getMedia',
			apiUrl: 'apiUrl',
			apiUrlPortal: 'apiUrlPortal',
			getCode: 'sub/getCode'
		}),
		filtered () {
			return this.mkbList.filter(f => f.name.includes(this.search));
		},
		paginatedMkb () {
			return this.filteredMkb.slice(0, this.limitMkb);
		},
		paginated () {
			return this.filtered.slice(0, this.limit);
		},
		filteredMkb () {
			return this.mkbList.filter(f => f.code.includes(this.searchMkb.toUpperCase()))
		},
		hasNextPage () {
			return this.paginated.length < this.filtered.length;
		}
	},
	data: () => ({
		email: '',
		activeTab: 1,
		dialog: false,
		subscriptions: [],
		pageSubscriptions: {
			mkbs: [],
			companies: []
		},
		searchResultDev: [],
		searchMkb: '',
		searchResultMkb: [],
		mkbList: [],
		сompaniesList: [],
		size: 10,
		resultCount: 0,
		pageNumber: 0,
		rules: {
			required: v => (!!v || v === 0 || v.length === 0) || 'Обязательно для заполнения!',
			requiredMultiple: (v) => v.length > 0 || 'Обязательно для заполнения.',
		},
	}),
	methods: {
		...mapActions({
			apiAll: 'api/apiAll'
		}),
		close() {
			this.dialog = false
			this.searchResultMkb = []
			this.searchResultDev = []
		},
		openDialog(){
			this.dialog = true
		},
		subscription() {
			this.$router.push(`/subscription`)
		},
		onCloseMkb() {
			this.observerMkb.disconnect();
		},
		deleteItems(id, flag){
			console.log(id, flag);
			if(confirm("Вы действительно хотите удалить подписку?"))
				this.api.post(`${this.apiUrl}api/Subscription/DeleteOptions`, {
					code: this.$route.params.code,
					mkbIds: flag === 1 ? [id] : [],
					companyIds: flag === 2 ? [id] : []
				})
				.then(res => {
					console.log(res);
					this.get()
				})
		},
		async onOpenMkb () {
			if (this.hasNextPageMkb) {
				await this.$nextTick();
				this.observerMkb.observe(this.$refs.load)
			}
		},
		async infiniteScroll ([{isIntersecting, target}]) {
			if (isIntersecting) {
				const ul = target.offsetParent;
				const scrollTop = target.offsetParent.scrollTop;
				this.limit += 10;
				await this.$nextTick();
				ul.scrollTop = scrollTop;
			}
		},
		async infiniteScrollMkb ([{isIntersecting, target}]) {
			if (isIntersecting) {
				const ul = target.offsetParent;
				const scrollTop = target.offsetParent.scrollTop;
				this.limitMkb += 10;
				await this.$nextTick();
				ul.scrollTop = scrollTop;
			}
		},
		updateTab(number) {
			this.activeTab = number;
			this.pageNumber = 0;

			if (this.activeTab === 1) {
				this.pageCount(this.subscriptions.mkbs.length);
			} else if (this.activeTab === 2) {
				this.pageCount(this.subscriptions.companies.length);
			}

			this.updatePage();
		},
		pageCount(count){
			this.resultCount = Math.ceil(count/this.size)
		},
		nextPage(){
			if (this.pageNumber + 1 >= this.resultCount) {
				return;
			}

			this.pageNumber++;
			this.updatePage();
		},
		prevPage(){
			if (this.pageNumber - 1 < 0) {
				return;
			}

			this.pageNumber--;
			this.updatePage();
		},
		updatePage() {
			let begin = this.pageNumber * this.size;
			let end = begin + this.size;

			if (this.activeTab === 1) {
				this.pageSubscriptions.mkbs = this.subscriptions.mkbs.slice(begin, end);
			} else if (this.activeTab === 2) {
				this.pageSubscriptions.companies = this.subscriptions.companies.slice(begin, end);
			}
		},
		updateListEvent(size) {
			this.size = size;
			this.updateTab(this.activeTab);
		},
		addOptions(){
			this.api.post(`${this.apiUrl}api/Subscription/AddOptions`, {
				code: this.$route.params.code,
				mkbIds: this.searchResultMkb.map(m => m.id),
				companyIds: this.searchResultDev
			})
			.then(res => {
				if (res.data.success) {
					this.$refs.snackbar.info('Подписка успешно добавлена');
					this.close()
					this.get()
				}
				else{
					this.$refs.snackbar.error(res.data.userMessage);
				}

			})
		},
		changeEmail(){
			this.api.put(`${this.apiUrl}api/Subscription/Update`,{
				code: this.$route.params.code,
  				email: this.email
			})
			.then(res => {
				console.log(res);
				this.$router.push(`/subscription-second/${this.email}`)
			})
		},
		get() {
			console.log(this.getCode);
			this.api.get(`${this.apiUrl}api/Subscription/GetOptions?code=${this.$route.params.code}`)
				.then(res => {
					this.subscriptions = res.data.result;
					this.updateTab(this.activeTab);
				});
			this.apiAll({type: 'get', url: `api/MKB`, id: undefined})
				.then(res => {
					for (let i = 0; i < res.data.length; i++) {
						res.data[i].codeName = res.data[i].code + ' ' + res.data[i].name
					}
					this.mkbList = res.data
				});
			this.apiAll({type: 'get', url: `api/Companies`, id: undefined}).then(res => { this.сompaniesList = res.data });
		}
	},
	mounted() {
		this.observer = new IntersectionObserver(this.infiniteScroll);
		this.observerMkb = new IntersectionObserver(this.infiniteScrollMkb);
	},
	created() {
		console.log(this.$route.params.code);
		this.email = this.$route.params.email
		this.get()
	}
}
</script>

<style scoped>
@import "../../views/Subscription/style/stylePages.css";
@import "../../views/Subscription/style/mobile.css";

.ring-load {
	position: absolute;
	cursor: progress;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	/* opacity: 0.4; */
	z-index: 10;
	background-color: rgba(88, 88, 88, 0.6);
}
.top{
	margin-top: 39px;
}

.ring-load-loading {
	left: 42%;
    top: 42%;
}

table tbody tr:hover {
	background: #f1f1f1;
	box-shadow: 0 1.5px 6px #00000080;
	cursor: pointer;
}
.v-dialog__container{
	display: flex;
}
.background-color{
	background: black;
	opacity: 0.8!important;
}
.title-block{
	display: flex;
	justify-content: space-between;
	padding: 20px;
	align-items: center;
	width: 100%;
}
.title-block1{
	display: flex;
	align-items: center;
	width: 100%;
}
.tabs-header {
	border-bottom: 3px solid #eee;
}
.tabs-btn {
	font-size: 18px;
	border: none;
	display: inline-block;
	background: #fff;
	padding: 10px 25px;
	text-align: center;
	cursor: pointer;
	margin-bottom: -3px;
	border-bottom: 3px solid #eee;
}
.tabs-btn.active {
	color: #0061d9;
	border-bottom: 3px solid #0061d9;
}
.tabs-body-item {
	padding: 20px 0;
}
</style>
